<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item>
            <DiscountsList v-if="tab == 0"></DiscountsList>
          </v-tab-item>

          <v-tab-item v-if="hasSEAPermissions">
            <DiscountsHistory v-if="tab == 1"></DiscountsHistory>
          </v-tab-item>

        </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'Discounts',

  data () {
    return {
      tab: null,
      items: ['List'],
      lsTab: 'discounts_tab',
    }
  },

  computed: {
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
  },

  components: {
    DiscountsHistory: () => import('@/components/discounts/DiscountsHistory.vue'),
    DiscountsList: () => import('@/components/discounts/DiscountsList.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }

    if (this.hasSEAPermissions) {
      this.items.push('History')
    }
  },
}
</script>

<style scoped>
</style>